<template>
  <div class="help">
    <el-row :gutter="20">
      <el-col :span="4"> <div class="blank_box"></div></el-col>
      <el-col :span="16">
        <div class="container help_content">
          <el-row :gutter="20" v-if="page01_flag">
            <el-col :span="5"><div class="blank_box"></div></el-col>
            <el-col :span="14">
              <div class="page_01">
                <p class="page_01_title">赛德部落帮助中心</p>
                <el-tabs v-model="activeName">
                  <!-- @tab-click="handleClick" -->
                  <el-tab-pane label="查询问题" name="1">
                    <div flex class="input_div">
                      <el-input
                        v-model="input_check"
                        placeholder="请输入问题关键词"
                        @keydown.native="enterSearch"
                      ></el-input>
                      <button class="key_button" @click="checkSearch">
                        搜索
                      </button>
                    </div>
                  </el-tab-pane>
                  <el-tab-pane label="问题追踪" name="2"
                    ><div flex class="input_div">
                      <el-input
                        v-model="input_trace"
                        placeholder="请输入流水号"
                        type="number"
                        @keydown.native="enterTrace"
                      ></el-input>
                      <button class="key_button" @click="traceSearch">
                        搜索
                      </button>
                    </div></el-tab-pane
                  >
                </el-tabs>
              </div>
            </el-col>
            <el-col :span="5"><div class="blank_box"></div></el-col>
          </el-row>
          <!-- 搜索关键词后的界面 -->
          <el-row :gutter="20" v-if="page02_flag">
            <el-col :span="24">
              <div class="page_02">
                <el-row :gutter="20">
                  <el-col :span="12">
                    <p class="page_02_title">
                      <span @click="backPage01">赛德部落帮助中心</span>
                    </p>
                  </el-col>
                  <el-col :span="12">
                    <div class="right">
                      <el-tabs v-model="activeName">
                        <el-tab-pane label="查询问题" name="1">
                          <div flex class="input_div">
                            <el-input
                              v-model="input_check"
                              placeholder="请输入问题关键词"
                              @keydown.native="enterSearch"
                            ></el-input>
                            <button
                              class="key_button page_button"
                              @click="check"
                            >
                              搜索
                            </button>
                          </div>
                        </el-tab-pane>
                        <el-tab-pane label="问题追踪" name="2"
                          ><div flex>
                            <el-input
                              type="number"
                              v-model="input_trace"
                              placeholder="请输入流水号"
                              @keydown.native="enterTrace"
                            ></el-input>
                            <button
                              class="key_button page_button"
                              @click="trace"
                            >
                              搜索
                            </button>
                          </div></el-tab-pane
                        >
                      </el-tabs>
                    </div>
                  </el-col>
                </el-row>
                <!-- 展示搜索结果 -->
                <el-row :gutter="20">
                  <el-col :span="24">
                    <div class="container result" flex column align>
                      <div v-if="!type" class="un_data" flex column center align>
                        <i class="iconfont icon-jilu"></i>
                        <p>
                          暂时没有相关问题，<span @click="creatQuestions"
                            >去提问</span
                          >
                        </p>
                      </div>
                      <div v-if="type === 1" class="un_data" flex column center align>
                        <i class="iconfont icon-noresult"></i>
                        <p>该问题正在受理</p>
                      </div>

                      <div v-if="type === 2" class="un_data" flex column center align>
                        <i class="iconfont icon-shurucuowutishi "></i>
                        <p>未查询到该流水号，请确认流水号是否正确</p>
                      </div>
                      <!-- 提问 -->
                      <div class="ask_questions" v-if="type === 3">
                        <el-form
                          label-width="0"
                          :model="ask_form"
                          :rules="ask_rule"
                          ref="ask_form"
                          flex
                          between
                        >
                          <el-form-item>
                            <el-input
                              placeholder="请输入联系人"
                              v-model="ask_form.name"
                              :maxlength="10"
                            >
                              <i
                                slot="prefix"
                                class="iconfont icon-portrait"
                              ></i>
                            </el-input>
                          </el-form-item>
                          <el-form-item prop="phone">
                            <el-input
                              placeholder="请输入联系电话"
                              v-model="ask_form.phone"
                              :maxlength="11"
                            >
                              <i
                                slot="prefix"
                                class="iconfont icon-dianhua"
                              ></i>
                            </el-input>
                          </el-form-item>
                          <el-form-item prop="email">
                            <el-input
                              placeholder="请输入联系邮箱"
                              v-model="ask_form.email"
                            >
                              <i
                                slot="prefix"
                                class="iconfont icon-youxiang"
                              ></i>
                            </el-input>
                          </el-form-item>
                        </el-form>
                        <el-input
                          type="textarea"
                          placeholder="具体问题"
                          v-model="ask_form.title"
                          class="text_area"
                          :maxlength="255"
                        >
                        </el-input>
                        <p class="ask_tips">具体问题不少于10个字</p>
                        <div flex class="up_file">
                          <UpImg
                            @change="changeFile"
                            type="0"
                            :is_over="is_over"
                          ></UpImg>
                          <UpImg
                            @change="changeFile"
                            type="1"
                            :is_over="is_over"
                          ></UpImg>
                          <UpImg
                            @change="changeFile"
                            type="2"
                            :is_over="is_over"
                          ></UpImg>
                        </div>
                        <button
                          class="key_button ask_button"
                          @click="submitAsk('ask_form')"
                        >
                          提交问题
                        </button>
                      </div>
                      <!-- 提交成功 -->
                      <div
                        class="submit_questions"
                        v-if="type === 4"
                        flex
                        column
                        around
                        align
                      >
                        <div class="q_top">
                          <i class="iconfont icon-chenggong"></i>
                          <p>您的问题已提交成功!</p>
                        </div>
                        <div class="q_bottom">
                          <p>
                            <i
                              class="iconfont icon-shiming"
                            ></i>
                            为了后期可在问题追踪里查询结果，
                          </p>
                          <p>请您务必记住以下工单号</p>
                          <div flex center align>
                            <p>工单号：</p>
                            <p id="number">{{ number }}</p>
                            <button
                              class="key_button sucess_button"
                              @click="copyNumber"
                            >
                              复制工单号
                            </button>
                          </div>
                        </div>
                      </div>
                      <!-- 查询结果 -->
                      <div class="check_show" v-if="type === 5">
                        <div
                          class="item"
                          v-for="(item, index) in check_list"
                          :key="index"
                          :class="cur_index === index ? 'checked' : ''"
                          flex
                          column
                          between
                          @mouseenter="hoverItem(index)"
                          @mouseleave="leaveItem"
                        >
                          <p
                            class="item_title"
                            @click="checkItem(item)"
                            v-html="item.title"
                          ></p>
                          <p
                            class="item_content"
                            v-html="item.content || '----'"
                          ></p>
                          <p class="item_bottom">
                            <i class="iconfont icon-shijian"></i>
                            {{ item.create_time }}
                          </p>
                        </div>
                      </div>
                      <CPage
                        :page="page"
                        :callback="callBack"
                        v-if="type === 5"
                      >
                      </CPage>
                      <!-- 查看详情 -->
                      <div class="check_detail" v-if="type === 6">
                        <h1>{{ cur_check.title }}</h1>
                        <p class="item_bottom">
                          <i class="iconfont icon-shijian"></i>
                          {{ cur_check.create_time }}
                        </p>
                        <p class="chceck_content">
                          {{ cur_check.content || "-----" }}
                        </p>
                        <div class="relpay_box">
                          <p
                            class="replay"
                            v-for="(item, index) in replay_list"
                            :key="index"
                          >
                            {{ item.content }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </el-col>
          </el-row>
        </div>
      </el-col>
      <el-col :span="4"> <div class="blank_box"></div></el-col>
    </el-row>
    <!-- bottom -->
    <!-- v-if="!page02_flag" -->
    <el-row :gutter="20" >
      <el-col :span="24">
        <div class="bottom">
          <el-row :gutter="20">
            <el-col :span="4"><div class="blank_box"></div></el-col>
            <el-col :span="16">
              <div class="cantainer bottom_content" flex center align>
                <p>
                  Copyright © 2020 成都赛德部落科技有限公司 All rights reserved
                  <span @click="toGoverment" style="cursor: pointer"
                    >蜀ICP备12005377号-2</span
                  >

                  <br />公司地址：
                  <span @click="checkAddress"
                    >成都高新南区天府大道北段1700号环球中心</span
                  >
                </p>
              </div></el-col
            >
            <el-col :span="4"><div class="blank_box"></div></el-col>
          </el-row>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import UpImg from "@/components/upload.vue";
import CPage from "@/components/c-page.vue";
import {
  upImg,
  submitQuestion,
  searchNumber,
  searchQuestion,
  // replayQuestion,
  detailQuestions,
} from "@/https/api.js";
export default {
  components: {
    UpImg,
    CPage,
  },
  data() {
    return {
      activeName: "1",
      input_check: "",
      input_trace: "",
      page01_flag: true,
      page02_flag: false,
      check_list: [],
      cur_check: {},
      trace_list: [],
      trace_flag: false,
      ask_form: {
        title: "",
        name: "",
        phone: "",
        email: "",
      },
      ask_rule: {
        phone: [
          {
            required: false,
            trigger: "blur",
            type:'number',
            validator: (rule, value, callback) => {
              if (value) {
                let regx = new RegExp(/^[1][3,4,5,7,8,9][0-9]{9}$/);
                if (regx.test(value)) {
                  return callback();
                }
                return callback(new Error("电话号码格式错误"));
              }
              return callback();
            },
          },
        ],
        email: [
          {
            required: false,
            trigger: "blur",
            validator: (rule, value, callback) => {
              if (value) {
                let regx = new RegExp(
                  /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/
                );
                if (!regx.test(value)) {
                  return callback(new Error("邮箱格式错误"));
                }
                return callback();
              }
              return callback();
            },
          },
        ],
        title: [
          {
            required: true,
            trigger: "blur",
            validator: (rule, value, callback) => {
              if (!value) {
                return callback(new Error("请填写具体问题"));
              } else if (value.length < 10) {
                return callback(new Error("具体问题不得少于十个字"));
              } else {
                return callback();
              }
            },
          },
        ],
      },
      cur_index: "",
      file_list: ["", "", ""],
      page: { page: 1, total: 0, limit: 10 },
      trace_result: [],
      is_over: false,
      type: 0,
      number: "000000",
      replay_list: [],
    };
  },
  mounted() {},
  watch: {
    input_check() {
      this.ask_form.title = this.input_check;
    },
  },
  methods: {
    callBack() {
      this.getCheckList();
    },
    //获取数据
    getCheckList() {
      searchQuestion({
        keyword: this.input_check,
        limit: this.page.limit,
        page: this.page.page,
      }).then((res) => {
        this.check_list = res.data.data;
        this.page.total = res.data.total;
        if (this.check_list&&this.check_list.length > 0) {
          this.check_list.forEach((item,index)=>{ //高亮字体添加
            item.title=this.hightLightText(item.title)
            item.content=this.hightLightText(item.content)
          })
          this.type = 5;
        } else {
          this.type = 0;
        }
      });
    },
    // 高亮显示匹配字段
    hightLightText(text) {
      // if (this.input_check) {
      //   this.check_list.forEach((item) => {
      //     if (item.title.indexOf(this.input_check) != -1) {
      //       let regx = new RegExp(this.input_check, "g");
      //       item.title = item.title.replace(
      //         regx,
      //         "<span class='hightlight'>" + this.input_check + "</span>"
      //       );
      //       item.content = item.content.replace(
      //         regx,
      //         "<span class='hightlight'>" + this.input_check + "</span>"
      //       );
      //     }
      //   });
      // }
      if(this.input_check&&text){
        let word=this.input_check
        let label=`<span style="color: #E64F1D">${word}</span>`
        let word_arr=text.split(`${word}`)
        let end_word=word_arr.join(`${label}`)
        return end_word
      }else{
        return text
      }
    },
    // //清除高亮效果
    // clear() {
    //   let hightlight = document.getElementsByClassName("hightlight");
    //   for (let i = 0; i < hightlight.length; i++) {
    //     hightlight[i].classList.remove("hightlight");
    //     // hightlight[i].style.cssText = "color:#333";
    //   }
    //   let title = document.getElementsByClassName("item_title");
    //   for (let i = 0; i < title.length; i++) {
    //     let high = document.getElementsByClassName("hightlight");
    //     high.forEach((el) => {
    //       // el.style.cssText = 'color:#333 !important'
    //       el.classList.remove("hightlight");
    //     });
    //   }
    // },
    //page1查询问题
    checkSearch() {
      this.page01_flag = false;
      this.page02_flag = true;
      this.activeName = "1";
      this.trace_flag = false;
      this.check();
    },
    //page1和page2的enter查询
    enterSearch(e) {
      if (e.keyCode == 13) {
        this.checkSearch();
      }
    },
    // page1和page2的enter追踪
    enterTrace(e) {
      if (e.keyCode == 13) {
        this.traceSearch();
      }
    },
    //page2查询问题
    check() {
      this.check_list = [];
      this.cur_check = {};
      this.getCheckList();
    },
    //page1追踪问题
    traceSearch() {
      this.page01_flag = false;
      this.page02_flag = true;
      this.activeName = "2";
      this.trace_flag = false;
      this.trace();
    },
    //page2追踪问题
    trace() {
      this.trance_flag = true;
      searchNumber({
        question_no: this.input_trace,
      }).then((res) => {
        // this.check_list = res.data;
        this.check_list = res.data.children;
        if (this.check_list.length > 0) {
          this.type = 5;
          this.activeName = "1";
        } else if (this.trace_flag) {
          this.type = 1;
        } else {
          if(res.data.title){
            this.type = 1;
          }else{
            this.type = 2;
          }
        }
      });
    },
    //上传图片
    changeFile(value, type) {
      upImg({
        photo: value,
      }).then((res) => {
        if (res.code === 0) {
          this.file_list[Number(type)] = res.data.url;
        }
      });
    },
    //返回到帮助中心首页
    backPage01() {
      this.page02_flag = false;
      this.page01_flag = true;
      this.activeName = "1";
      this.type = 0;
      this.input_check = "";
      this.input_trace = "";
    },
    //去提问
    creatQuestions() {
      this.type = 3;
      this.ask_form.title = this.input_check;
      // this.resetForm('ask_form')
    },
    //提交问题
    submitAsk(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.ask_form.title.length > 10) {
            submitQuestion({
              ...this.ask_form,
              images: this.file_list.join(","),
            }).then((res) => {
              if (res.code === 0) {
                this.$alert("您的问题已提交!", "提示", {
                  confirmButtonText: "确定",
                });
                this.number = res.data;
                this.file_list = [];
                this.is_over = true;
                this.ask_form = {};
                this.type = 4;
                this.resetForm("ask_form");
              }
            });
          } else {
            this.$alert("具体问题不得少于十个字!", "提示", {
              confirmButtonText: "确定",
            });
          }
        } else {
          this.$alert("您填写的信息不正确!", "提示", {
            confirmButtonText: "确定",
          });
          return false;
        }
      });
    },
    //复制工单号
    copyNumber() {
      let number = document.getElementById("number").innerText;
      var input = document.createElement("input");
      input.value = number;
      input.readOnly = true;
      document.body.appendChild(input);
      input.select();
      input.setSelectionRange(0, input.value.length);
      document.execCommand("Copy");
      this.$alert("工单号已复制!", "提示", {
        confirmButtonText: "确定",
      });
      document.body.removeChild(input);
    },
    //鼠标移入
    hoverItem(index) {
      this.cur_index = index;
    },
    //鼠标移出
    leaveItem() {
      this.cur_index = "";
    },
    //点击查看详情
    checkItem(row) {
      detailQuestions({
        question_id: row.id,
      }).then((res) => {
        // this.cur_check = res.data[0];
        this.cur_check = res.data;
        this.type = 6;
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    //打开政务服务平台
    toGoverment() {
      window.open("https://ythzxfw.miit.gov.cn/index", "blank");
    },
    //打开百度地图查看公司地址
    checkAddress() {
      window.open(
        "https://map.baidu.com/search/%E6%88%90%E9%83%BD%E9%AB%98%E6%96%B0%E5%8D%97%E5%8C%BA%E5%A4%A9%E5%BA%9C%E5%A4%A7%E9%81%93%E5%8C%97%E6%AE%B51700%E5%8F%B7%E7%8E%AF%E7%90%83%E4%B8%AD%E5%BF%83/@11584942.818870967,3555747.75,18.49z?querytype=con&wd=%E6%88%90%E9%83%BD%E9%AB%98%E6%96%B0%E5%8D%97%E5%8C%BA%E5%A4%A9%E5%BA%9C%E5%A4%A7%E9%81%93%E5%8C%97%E6%AE%B51700%E5%8F%B7%E7%8E%AF%E7%90%83%E4%B8%AD%E5%BF%83&c=75&provider=pc-aladin&pn=0&device_ratio=1&da_src=shareurl",
        "blank"
      );
    },
    //tab点击
    // handleClick(tab, event) {
    //   console.log(tab, event);
    // },
    // getReplay(){
    //   replayQuestion({

    //   }).then(res => {
    //     this.replay_list = res.data
    //   })
    // }
  },
};
</script>
<style lang="less" scoped>
.help {
  min-width: 1200px;
  // overflow-y: auto;
  /deep/ .el-tabs__nav-wrap::after {
    height: 0;
  }
  /deep/ .el-tabs__active-bar {
    background-color: #f78900;
  }
  /deep/ .el-tabs__item {
    font-size: 16px;
  }
  /deep/ .el-tabs__item:hover,
  /deep/ .el-tabs__item.is-active {
    color: #f78900;
    .iconfont {
      color: #f78900;
    }
  }
  /deep/ .el-input {
    text-align: left;
    // width: 400px;
  }
  /deep/ .el-input__inner {
    width: 490px;
  }
  .key_button {
    // border-radius: 0;
  }
  .help_content {
    position: relative;
    top: 80px;
    background-color: #fff;
    min-height: calc(100vh - 80px - 127px);
    overflow: auto;

    .page_01 {
      .page_01_title {
        font-size: 36px;
        font-family: Source Han Sans SC;
        font-weight: 500;
        color: #cccccc;
        line-height: 46px;
        margin-top: 100px;
        margin-bottom: 80px;
      }
      .key_button {
        width: 180px;
        height: 65px;
        margin-left: 20px;
      }
      /deep/ .el-input__inner {
        height: 65px;
        font-size: 18px;
      }
    }
    .page_02 {
      .page_02_title {
        text-align: left;
        > span {
          font-size: 28px;
          font-family: Source Han Sans SC;
          font-weight: 400;
          color: #666666;
          line-height: 46px;
          margin-top: 30px;
          cursor: pointer;
        }
        > span:hover {
          color: #f78900;
        }
      }
      .page_button {
        width: 140px;
        height: 45px;
        margin-left: 20px;
        font-size: 16px;
      }
      /deep/ .el-input__inner {
        height: 45px;
        font-size: 16px;
      }
      .right {
        padding-top: 45px;
      }
      .result {
        min-height: 450px;
        background: #ffffff;
        // box-shadow: 1px 4px 10px 1px rgba(15, 15, 15, 0.08);
        box-shadow: rgba(15, 15, 15, 0.08) 1px 4px 10px 1px;
        margin-top: 30px;
        margin-bottom: 175px;
        overflow-y: auto;
        width: 98%;
        .un_data {
          width: 100%;
          height: 380px;
          .iconfont {
            font-size: 120px;
            color: rgba(224, 224, 224, 1);
            opacity: 0.5;
            // box-shadow: rgba(15, 15, 15, 0.08) 1px 4px 10px 1px;
          }
          p {
            padding-top: 50px;
            color: #4d4d4d;
            > span {
              cursor: pointer;
              color: #f78900;
            }
          }
        }
        .ask_questions {
          width: 99%;
          height: 665px;
          padding: 65px 75px;
          /deep/ .el-input__inner {
            width: 300px;
            height: 50px;
            padding-left: 50px;
            background-color: #fafafa;
          }
          /deep/ .el-textarea__inner {
            background-color: #fafafa;
            height: 240px;
          }
          /deep/ .el-input__prefix {
            display: flex;
            justify-content: center;
            align-items: center;
            .iconfont {
              font-size: 30px;
            }
          }
          .ask_tips {
            color: #cccccc;
            font-size: 14px;
            position: relative;
            top: -25px;
            text-align: right;
            padding-right: 20px;
          }
          .ask_button {
            background: rgba(111, 126, 141, 0);
            border: 1px solid #f78900;
            color: #f78900;
            border-radius: 4px;
            font-size: 18px;
            width: 190px;
            height: 50px;
          }
          .ask_button:hover {
            color: #fff;
            background-color: #f78900;
          }
          .up_file {
            padding: 20px 0 40px;
            height: 186px;
          }
        }
        .submit_questions {
          width: 99%;
          height: 450px;
          p {
            font-size: 24px;
            font-family: Source Han Sans SC;
            font-weight: 400;
            color: #4d4d4d;
            line-height: 48px;
          }
          .q_top {
            height: 40%;
            .iconfont {
              color: rgba(58, 155, 251, 1);
              font-size: 60px;
              margin: 30px 0 0 0;
            }
            > p {
              margin: 20px 0;
            }
          }
          .q_bottom {
            height: 60%;
            .iconfont {
              color: rgba(251, 73, 73, 1);
              font-size: 28px;
              padding: 8px;
            }
            > div {
              > p:nth-of-type(2) {
                color: rgba(249, 62, 62, 1);
                padding-right: 20px;
              }
              .sucess_button {
                width: 140px;
                height: 44px;
                border: 1px solid #f78900;
                border-radius: 4px;
                color: #f78900;
                background-color: #fff;
              }
              .sucess_button:hover {
                color: #fff;
                background-color: #f78900;
              }
            }
          }
        }
        .check_show {
          min-height: 600px;
          width: 97%;
          margin: 0 auto;
          .item {
            height: 180px;
            padding: 20px 10px;
            width: 100%;
            border-left: 4px solid #fff;
            p {
              font-size: 16px;
              color: #303030;
              text-align: left;
            }
            .item_title {
              font-size: 20px;
              color: #666666;
              cursor: pointer;
            }
            .item_title:hover {
              color: #f78900;
              span {
                color: #f78900;
              }
            }

            .item_bottom {
              padding: 20px 0;
              border-bottom: 1px solid #e6e6e6;
              .iconfont {
                color: #ddd;
                font-size: 14px;
              }
            }
          }
          .checked {
            border-left: 4px solid #f78900;
            background-color: #fafafa;
          }
        }
        .check_detail {
          width: 100%;
          height: 400px;
          padding: 20px;
          .item_bottom {
            text-align: center;
            padding: 20px 0;
            .iconfont {
              color: #ddd;
              font-size: 14px;
            }
          }
          .chceck_content {
            padding: 20px;
            background-color: #fafafa;
          }
        }
      }
    }
  }
  .bottom {
    height: 127px;
    // background-color: rgba(55, 56, 64, 1);
    position: relative;
    bottom: -80px;
    .bottom_content {
      height: 127px;
    }
    p {
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #b0b0b0;
      line-height: 24px;
      > span {
        cursor: pointer;
      }
      > span:hover {
        border-bottom: 1px solid rgba(176, 176, 176, 1);
      }
    }
  }
}
@media screen and(max-width:1200px) {
  .help {
    /deep/ .el-input__inner {
      width: 380px;
    }
    .help_content {
      .page_01 {
        .page_01_title {
          font-size: 28px;
          line-height: 46px;
          margin-top: 100px;
          margin-bottom: 80px;
        }
        .key_button {
          width: 100px;
          height: 40px;
          margin-left: 10px;
          font-size: 14px;
        }
        /deep/ .el-input__inner {
          height: 40px;
          font-size: 14px;
        }
      }
      .page_02 {
        .page_02_title {
          font-size: 22px;
          line-height: 46px;
          margin-top: 30px;
        }
        .page_button {
          width: 100px;
          height: 40px;
          margin-left: 10px;
          font-size: 14px;
        }
        /deep/ .el-input__inner {
          width: 300px;
          height: 40px;
          font-size: 14px;
        }
        .right {
          padding-top: 45px;
        }
        .result {
          min-height: 350px;
          .ask_questions {
            height: 600px;
            padding: 50px;
            /deep/ .el-input__inner {
              width: 200px;
              height: 50px;
              padding-left: 50px;
            }
            /deep/ .el-textarea__inner {
              height: 200px;
            }
            /deep/ .el-input__prefix {
              .iconfont {
                font-size: 30px;
              }
            }
            .ask_button {
              font-size: 16px;
              width: 120px;
              height: 40px;
            }
            .up_file {
              padding: 20px 0 40px;
              height: 186px;
            }
          }
          .submit_questions {
            height: 350px;
            p {
              font-size: 18px;
              line-height: 30px;
            }
            .q_top {
              height: 40%;
              .iconfont {
                font-size: 45px;
                margin: 20px 0 0 0;
              }
              > p {
                margin: 20px 0;
              }
            }
            .q_bottom {
              height: 60%;
              .iconfont {
                font-size: 22px;
                padding: 5px;
              }
              > div {
                > p:nth-of-type(2) {
                  padding-right: 10px;
                }
                .sucess_button {
                  width: 100px;
                  height: 40px;
                  font-size: 14px;
                }
              }
            }
          }
          .check_show {
            min-height: 600px;
            width: 97%;
            .item {
              height: 180px;
              padding: 20px 10px;
              p {
                font-size: 16px;
              }
              .item_title {
                font-size: 20px;
              }

              .item_bottom {
                padding: 20px 0;
              }
            }
          }
        }
      }
    }
  }
}
@media screen and(min-width:1200.1px) and(max-width:1500px) {
  .help {
    /deep/ .el-input__inner {
      width: 300px;
    }
    .help_content {
      .page_01 {
        .page_01_title {
          font-size: 30px;
          line-height: 46px;
          margin-top: 100px;
          margin-bottom: 80px;
        }
        .key_button {
          width: 120px;
          height: 45px;
          margin-left: 10px;
          font-size: 16px;
        }
        /deep/ .el-input__inner {
          height: 45px;
          font-size: 16px;
          width: 320px;
        }
      }
      .page_02 {
        .input_div {
          width: 420px;
        }
        .page_02_title {
          font-size: 24px;
          line-height: 46px;
          margin-top: 30px;
        }
        .page_button {
          width: 120px;
          height: 45px;
          // margin-left: 10px;
          font-size: 16px;
        }
        /deep/ .el-input__inner {
          width: 300px;
          height: 45px;
          font-size: 16px;
        }
        .right {
          padding-top: 45px;
        }
        .result {
          min-height: 380px;
          .ask_questions {
            height: 600px;
            padding: 50px;
            /deep/ .el-input__inner {
              width: 225px;
              height: 50px;
              padding-left: 50px;
            }
            /deep/ .el-textarea__inner {
              height: 200px;
            }
            /deep/ .el-input__prefix {
              .iconfont {
                font-size: 30px;
              }
            }
            .ask_button {
              font-size: 16px;
              width: 130px;
              height: 45px;
            }
            .up_file {
              padding: 20px 0 40px;
              height: 186px;
            }
          }
          .submit_questions {
            height: 380px;
            p {
              font-size: 20px;
              line-height: 30px;
            }
            .q_top {
              height: 40%;
              .iconfont {
                font-size: 50px;
                margin: 20px 0 0 0;
              }
              > p {
                margin: 20px 0;
              }
            }
            .q_bottom {
              height: 60%;
              .iconfont {
                font-size: 24px;
                padding: 5px;
              }
              > div {
                > p:nth-of-type(2) {
                  padding-right: 20px;
                }
                .sucess_button {
                  width: 120px;
                  height: 45px;
                  font-size: 16px;
                }
              }
            }
          }
          .check_show {
            min-height: 600px;
            width: 97%;
            .item {
              height: 180px;
              padding: 20px 10px;
              p {
                font-size: 16px;
              }
              .item_title {
                font-size: 20px;
              }

              .item_bottom {
                padding: 20px 0;
              }
            }
          }
        }
      }
    }
  }
}
@media screen and(min-width:1500.1px) {
  .help {
    /deep/ .el-input__inner {
      width: 300px;
    }
    .help_content {
      .page_01 {
        .page_01_title {
          font-size: 30px;
          line-height: 46px;
          margin-top: 100px;
          margin-bottom: 80px;
        }
        .key_button {
          width: 190px;
          height: 45px;
          margin-left: 10px;
          font-size: 16px;
        }
        /deep/ .el-input__inner {
          height: 45px;
          font-size: 16px;
          width: 490px;
        }
      }
      .page_02 {
        // .input_div {
        //   width: 520px;
        // }
        .page_02_title {
          font-size: 24px;
          line-height: 46px;
          margin-top: 30px;
        }
        .page_button {
          width: 100px;
          height: 45px;
          margin-left: 0;
          font-size: 16px;
        }
        /deep/ .el-input__inner {
          width: 400px;
          height: 45px;
          font-size: 16px;
        }
        .right {
          padding-top: 45px;
        }
        .result {
          min-height: 380px;
          .ask_questions {
            height: 600px;
            padding: 50px;
            /deep/ .el-input__inner {
              width: 225px;
              height: 50px;
              padding-left: 50px;
            }
            /deep/ .el-textarea__inner {
              height: 200px;
            }
            /deep/ .el-input__prefix {
              .iconfont {
                font-size: 30px;
              }
            }
            .ask_button {
              font-size: 16px;
              width: 130px;
              height: 45px;
            }
            .up_file {
              padding: 20px 0 40px;
              height: 186px;
            }
          }
          .submit_questions {
            height: 380px;
            p {
              font-size: 20px;
              line-height: 30px;
            }
            .q_top {
              height: 40%;
              .iconfont {
                font-size: 50px;
                margin: 20px 0 0 0;
              }
              > p {
                margin: 20px 0;
              }
            }
            .q_bottom {
              height: 60%;
              .iconfont {
                font-size: 24px;
                padding: 5px;
              }
              > div {
                > p:nth-of-type(2) {
                  padding-right: 20px;
                }
                .sucess_button {
                  width: 120px;
                  height: 45px;
                  font-size: 16px;
                }
              }
            }
          }
          .check_show {
            min-height: 600px;
            width: 97%;
            .item {
              height: 180px;
              padding: 20px 10px;
              p {
                font-size: 16px;
              }
              .item_title {
                font-size: 20px;
              }

              .item_bottom {
                padding: 20px 0;
              }
            }
          }
        }
      }
    }
  }
}
.light_text{
  color: #E64F1D !important
}
</style>